import { formatDate } from '../../reducers/filters';
import { dynamicFilters } from './dynamicFilters';

export const actions = {
  FETCH_BOOKINGS_SUCCESS: 'FETCH_BOOKINGS_SUCCESS',
  FETCH_BOOKING_ITEMS_SUCCESS: 'FETCH_BOOKING_ITEMS_SUCCESS',
  FETCH_ALL_BOOKINGS_METADATA: 'FETCH_ALL_BOOKINGS_METADATA',
  FETCH_ALL_BOOKING_ITEMS_METADATA: 'FETCH_ALL_BOOKING_ITEMS_METADATA',
  SET_SORT_BY: 'SET_SORT_BY',
  CHANGE_PAGINATION: 'CHANGE_PAGINATION',
  RESET: 'RESET',
  RESET_EXCEPT_FILTERS: 'RESET_EXCEPT_FILTERS',
};

export const defaultFilters = {
  keywordSearch: '',
  startDate: '',
  endDate: '',
  statuses: [],
  createdByUsers: [],
  venues: [],
  spaces: [],
  services: [],
  paymentStatuses: [],
};

export const initialState = {
  bookings: [],
  bookingItems: [],
  allBookingsCount: '',
  error: false,
  filtersEnabled: true,
  sortBy: 'id',
  totalCount: '',
  applyFilters: true,
  count: '',
  initialized: false,
  filters: { ...defaultFilters },
  pagination: {
    currentPage: 1,
    perPage: 10,
    totalCount: '',
    count: '',
  },
  previousRequestFilters: {},
  apiCheckedDefaultFilters: { ...defaultFilters },
  report: {},
};

export const initialiseState = ({
  filters = defaultFilters,
  apiCheckedDefaultFilters = defaultFilters,
  ...state
} = initialState) => {
  const startDate = formatDate();

  return {
    ...state,
    filters: { ...filters, startDate },
    apiCheckedDefaultFilters: { ...apiCheckedDefaultFilters, startDate },
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.RESET:
      return initialiseState(initialState);
    case actions.RESET_EXCEPT_FILTERS:
      return {
        ...initialiseState(initialState),
        filters: state.filters,
        apiCheckedDefaultFilters: state.apiCheckedDefaultFilters,
      };
    case actions.SET_SORT_BY:
      return {
        ...state,
        sortBy: action.payload.sortBy,
        pagination: { ...state.pagination, currentPage: 1 },
      };
    case actions.FETCH_ALL_BOOKINGS_METADATA:
      return {
        ...state,
        allBookingsCount: action.payload.allBookingsCount,
        filters: { ...state.filters, ...action.payload.filters },
        apiCheckedDefaultFilters: {
          ...state.filters,
          ...action.payload.filters,
        },
      };
    case actions.FETCH_ALL_BOOKING_ITEMS_METADATA:
      return {
        ...state,
        allBookingsCount: action.payload.allBookingItemsCount,
        filters: { ...state.filters, ...action.payload.filters },
        apiCheckedDefaultFilters: {
          ...state.filters,
          ...action.payload.filters,
        },
      };
    case actions.CHANGE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          currentPage: action.payload.page,
          perPage: action.payload.perPage,
        },
      };
    case actions.FETCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        initialized: true,
        bookings: action.payload.bookings,
        pagination: {
          ...action.payload.meta.pagination,
          currentPage: state.pagination.currentPage,
        },
        filters: dynamicFilters(state.filters, action.payload.meta.aggs),
        previousRequestFilters: dynamicFilters(
          state.filters,
          action.payload.meta.aggs
        ),
      };
    case actions.FETCH_BOOKING_ITEMS_SUCCESS:
      return {
        ...state,
        initialized: true,
        bookingItems: action.payload.bookingItems,
        pagination: action.payload.meta.pagination,
        filters: dynamicFilters(state.filters, action.payload.meta.aggs),
        previousRequestFilters: dynamicFilters(
          state.filters,
          action.payload.meta.aggs
        ),
      };
    default:
      return state;
  }
};

export default reducer;

import moment from 'moment';
import {
  formatDate as defaultFormatDate,
  dateFormatForAPI,
} from '../../utils/date';

export const formatDate = (date, format = dateFormatForAPI) =>
  defaultFormatDate(date, format);

export const filterActions = {
  ENABLE_FILTERS: 'ENABLE_FILTERS',
  DISABLE_FILTERS: 'DISABLE_FILTERS',
  SET_KEYWORD_FILTER: 'SET_KEYWORD_FILTER',
  SET_TYPE: 'SET_TYPE',
  APPLY_FILTERS: 'APPLY_FILTERS',
  CHECK_ALL_FILTERS: 'CHECK_ALL_FILTERS',
  UNCHECK_FILTERS: 'UNCHECK_FILTERS',
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  SET_SELECTED_ITEM: 'SET_SELECTED_ITEM',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
};

const setAppliedValues = (checkBoxFilterNames, filters) => {
  return checkBoxFilterNames
    .map(filterName => {
      return {
        [filterName]: filters[filterName].map(filterItem => {
          if (filterItem.checked && filterItem.visible) {
            return {
              ...filterItem,
              applied: true,
            };
          }
          return {
            ...filterItem,
            applied: false,
          };
        }),
      };
    })
    .reduce((result, current) => {
      return Object.assign(result, current);
    }, {});
};

export const filtersReducer = (state, action) => {
  const {
    startDate = state.startDate || undefined,
    endDate = state.endDate || undefined,
  } = action.payload || {};

  switch (action.type) {
    case filterActions.SET_START_DATE:
      return {
        ...state,
        pagination: { ...state.pagination, currentPage: 1 },
        filters: {
          ...state.filters,
          startDate: formatDate(startDate),
          endDate:
            endDate && moment(endDate).isSameOrAfter(startDate)
              ? formatDate(endDate)
              : '',
        },
      };
    case filterActions.SET_END_DATE:
      return {
        ...state,
        pagination: { ...state.pagination, currentPage: 1 },
        filters: {
          ...state.filters,
          endDate: formatDate(endDate),
        },
      };
    case filterActions.SET_SELECTED_ITEM:
      return {
        ...state,
        filters: {
          ...state.filters,
          latestAppliedFilter: action.payload.filterKey,
          [action.payload.filterKey]: [
            ...state.filters[action.payload.filterKey].filter(
              filterItem =>
                !!filterItem.value &&
                filterItem.value.toString() !==
                  action.payload.selectedValue.toString()
            ),
            {
              ...state.filters[action.payload.filterKey].find(filterItem => {
                return (
                  filterItem.value.toString() ===
                  action.payload.selectedValue.toString()
                );
              }),
              checked: action.payload.checked,
            },
          ],
        },
      };
    case filterActions.ENABLE_FILTERS:
      return { ...state, filtersEnabled: true };
    case filterActions.APPLY_FILTERS:
      return {
        ...state,
        loading: true,
        applyFilters: true,
        pagination: { ...state.pagination, currentPage: 1 },
        filters: {
          ...state.filters,
          ...setAppliedValues(
            action.payload.checkBoxFilterNames,
            state.filters
          ),
        },
      };
    case filterActions.DISABLE_FILTERS:
      return {
        ...state,
        filtersEnabled: false,
        loading: true,
        applyFilters: false,
        filters: { ...state.apiCheckedDefaultFilters },
      };
    case filterActions.CLEAR_FILTERS:
      return {
        ...state,
        loading: true,
        filters: { ...state.apiCheckedDefaultFilters },
      };
    case filterActions.UNCHECK_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: state.filters[
            action.payload.filterName
          ].map(filterItem => {
            return {
              ...filterItem,
              checked: false,
            };
          }),
        },
      };
    case filterActions.CHECK_ALL_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterName]: state.filters[
            action.payload.filterName
          ].map(filterItem => {
            return {
              ...filterItem,
              checked: filterItem.visible,
            };
          }),
        },
      };
    case filterActions.SET_KEYWORD_FILTER:
      return {
        ...state,
        filters: { ...state.filters, keywordSearch: action.payload.keyword },
      };
    case filterActions.SET_TYPE:
      return {
        ...state,
        filters: { ...state.filters, type: action.payload.type },
      };

    default:
      return state;
  }
};

import { isApplied } from '../utils/dynamicFilters';

export const filtersQueryBuilder = filters => {
  const query = {};

  const {
    keywordSearch,
    startDate,
    endDate,
    statuses,
    createdByUsers,
    venues,
    spaces,
    services,
    paymentStatuses,
  } = filters;

  const getValue = i => {
    return i.value;
  };

  if (keywordSearch) {
    query.search = keywordSearch;
  }

  if (startDate) {
    query.startDate = startDate;
  }

  if (endDate) {
    query.endDate = endDate;
  }

  if (statuses.filter(isApplied).length > 0) {
    query.status = statuses.filter(isApplied).map(getValue);
  }

  if (createdByUsers.filter(isApplied).length > 0) {
    query.createdByUserIds = createdByUsers.filter(isApplied).map(getValue);
  }

  if (services.filter(isApplied).length > 0) {
    query.serviceIds = services.filter(isApplied).map(getValue);
  }

  if (venues.filter(isApplied).length > 0) {
    query.venueIds = venues.filter(isApplied).map(getValue);
  }

  if (spaces.filter(isApplied).length > 0) {
    query.spaceIds = spaces.filter(isApplied).map(getValue);
  }

  if (paymentStatuses.filter(isApplied).length > 0) {
    query.paymentStatuses = paymentStatuses.filter(isApplied).map(getValue);
  }

  return query;
};

const sortByQueryBuilder = sortBy => {
  if (sortBy) {
    const [sortField, sortDirection] = sortBy.split(':');
    return { sortField, sortDirection };
  }

  return {};
};

const paginationQueryBuilder = (
  { perPage, currentPage },
  { keywordSearch }
) => {
  if (keywordSearch) {
    return { perPage };
  }
  return { perPage, page: currentPage };
};

const bookingsQueryBuilder = (filters, sortBy, pagination) => {
  return {
    ...filtersQueryBuilder(filters),
    ...sortByQueryBuilder(sortBy),
    ...paginationQueryBuilder(pagination, filters),
  };
};

export default bookingsQueryBuilder;

import {
  createContext,
  useReducer,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useRouter } from 'next/router';
import { aggItemsToCheckboxFilterItem } from 'utils/dynamicFilters';
import { combineReducers } from 'utils/reducers';
import reducer, { initialState, initialiseState, actions } from './reducer';
import bookingsParamsBuilder, {
  filtersQueryBuilder,
} from '../../services/bookingsQueryBuilder';
import { AuthContext } from '../auth';

import { filtersReducer, filterActions } from '../../reducers/filters';
import { reportsReducer, reportsActions } from '../../reducers/reports';

// hooks
import { useInterval } from '../../hooks/useInterval';
import { useApiRequest } from '../../hooks/useApiRequest';

import { PROVISIONAL, CANCELLED, CONFIRMED } from '../../constants/bookings';

export const BookingsContext = createContext({
  loading: false,
  metadataLoaded: false,
  ...initialState,
  actions: {
    fetchBookings: async () => {},
    fetchBookingItems: async () => {},
    clearReport: async () => {},
    createReport: async () => {},
    fetchReport: async () => {},
    fetchAllBookingsMetadata: async () => {},
    fetchAllBookingItemsMetadata: async () => {},
    setSelectedItem: () => {},
    uncheckFilters: () => {},
    checkAllFilters: () => {},
    clearFilters: () => {},
    enableFilters: () => {},
    disableFilters: () => {},
    handleSortByChange: () => {},
    handleKeywordSearch: () => {},
    applyFilters: () => {},
    changePagination: () => {},
    handleStartDateChange: () => {},
    handleEndDateChange: () => {},
    removeBookingItems: () => {},
  },
});

const combinedReducers = combineReducers(
  filtersReducer,
  reportsReducer,
  reducer
);

const isBookingsPage = (pathname = '/') => {
  const [first, second] = pathname.slice(1).split('/');
  return first === 'my-account' && second === 'bookings';
};

const BookingsProvider = ({ children }) => {
  const [
    {
      bookings,
      bookingItems,
      filtersEnabled,
      sortBy,
      filters,
      totalCount,
      count,
      allBookingsCount,
      initialized,
      pagination,
      report,
    },
    dispatch,
  ] = useReducer(combinedReducers, initialState, initialiseState);

  const [loading, setLoading] = useState(true);
  const [metadataLoaded, setMetadataLoaded] = useState(false);

  const router = useRouter();

  useEffect(() => {
    dispatch({
      type: isBookingsPage(router.pathname)
        ? actions.RESET_EXCEPT_FILTERS
        : actions.RESET,
    });
  }, [router.pathname]);

  const apiRequest = useApiRequest();

  // Serialise the parameters so that the hooks only fire when the
  // actual content changes
  const bookingsParamsJson = useMemo(
    () => JSON.stringify(bookingsParamsBuilder(filters, sortBy, pagination)),
    [filters, sortBy, pagination]
  );

  const fetchBookings = useCallback(async () => {
    setLoading(true);

    const {
      data: { bookings: apiItems, meta },
    } = await apiRequest.get('/v1/bookings', {
      params: JSON.parse(bookingsParamsJson),
    });

    dispatch({
      type: actions.FETCH_BOOKINGS_SUCCESS,
      payload: {
        bookings: apiItems,
        meta,
      },
    });

    setLoading(false);
  }, [bookingsParamsJson, apiRequest]);

  const fetchBookingItems = useCallback(async () => {
    setLoading(true);

    const {
      data: { bookingItems: apiItems, meta },
    } = await apiRequest.get('/v1/booking_items', {
      params: JSON.parse(bookingsParamsJson),
    });

    dispatch({
      type: actions.FETCH_BOOKING_ITEMS_SUCCESS,
      payload: {
        bookingItems: apiItems,
        meta,
      },
    });

    setLoading(false);
  }, [bookingsParamsJson, apiRequest]);

  const clearReport = useCallback(async () => {
    dispatch({ type: reportsActions.CLEAR_REPORT });
  }, []);

  const filtersQueryJson = useMemo(
    () => JSON.stringify(filtersQueryBuilder(filters)),
    [filters]
  );

  const createReport = useCallback(async () => {
    const { data: apiReport } = await apiRequest.post(
      '/v1/booking_items/create_report',
      JSON.parse(filtersQueryJson)
    );

    dispatch({
      type: reportsActions.CREATE_REPORT,
      payload: {
        report: apiReport,
      },
    });
  }, [filtersQueryJson, apiRequest]);

  const fetchReport = useCallback(async () => {
    const { data: apiReport } = await apiRequest.get(
      `/v1/reports/${report.id}`
    );

    dispatch({
      type: reportsActions.CREATE_REPORT,
      payload: {
        report: apiReport,
      },
    });
  }, [report.id, apiRequest]);

  const fetchAllBookingsMetadata = useCallback(async () => {
    const {
      data: {
        meta: {
          pagination: { totalCount: apiAllBookingsCount },
          aggs,
        },
      },
    } = await apiRequest.get(`/v1/bookings`, {
      params: { perPage: 1 },
    });
    const statuses = [{ id: CONFIRMED }, { id: CANCELLED }, { id: PROVISIONAL }]
      .map(status => {
        return {
          ...status,
          name: status.id.replace(/^\w/, c => c.toUpperCase()),
        };
      })
      .map(aggItemsToCheckboxFilterItem);
    const createdByUsers = aggs.createdByUsers.map(
      aggItemsToCheckboxFilterItem
    );
    const venues = aggs.venues.map(aggItemsToCheckboxFilterItem);
    const spaces = aggs.spaces.map(aggItemsToCheckboxFilterItem);
    const services = aggs.services.map(aggItemsToCheckboxFilterItem);
    const paymentStatuses = aggs.paymentStatuses.map(
      aggItemsToCheckboxFilterItem
    );
    dispatch({
      type: actions.FETCH_ALL_BOOKINGS_METADATA,
      payload: {
        allBookingsCount: apiAllBookingsCount,
        filters: {
          statuses,
          createdByUsers,
          venues,
          spaces,
          services,
          paymentStatuses,
        },
      },
    });

    setMetadataLoaded(true);
  }, [apiRequest]);

  const fetchAllBookingItemsMetadata = useCallback(async () => {
    const {
      data: {
        meta: {
          pagination: { totalCount: apiAllBookingItemsCount },
          aggs,
        },
      },
    } = await apiRequest.get(`/v1/booking_items`, {
      params: { perPage: 1 },
    });
    const statuses = aggs.statuses.map(aggItemsToCheckboxFilterItem);
    const createdByUsers = aggs.createdByUsers.map(
      aggItemsToCheckboxFilterItem
    );
    const venues = aggs.venues.map(aggItemsToCheckboxFilterItem);
    const spaces = aggs.spaces.map(aggItemsToCheckboxFilterItem);
    const services = aggs.services.map(aggItemsToCheckboxFilterItem);
    const paymentStatuses = aggs.paymentStatuses.map(
      aggItemsToCheckboxFilterItem
    );

    dispatch({
      type: actions.FETCH_ALL_BOOKING_ITEMS_METADATA,
      payload: {
        allBookingItemsCount: apiAllBookingItemsCount,
        filters: {
          statuses,
          createdByUsers,
          venues,
          spaces,
          services,
          paymentStatuses,
        },
      },
    });

    setMetadataLoaded(true);
  }, [apiRequest]);

  const setSelectedItem = useCallback((filterKey, value, checked) => {
    dispatch({
      type: filterActions.SET_SELECTED_ITEM,
      payload: {
        selectedValue: value,
        filterKey,
        checked,
      },
    });
  }, []);

  const uncheckFilters = useCallback(filterName => {
    return () => {
      dispatch({
        type: filterActions.UNCHECK_FILTERS,
        payload: {
          filterName,
        },
      });
    };
  }, []);

  const checkAllFilters = useCallback(filterName => {
    return () => {
      dispatch({
        type: filterActions.CHECK_ALL_FILTERS,
        payload: {
          filterName,
        },
      });
    };
  }, []);

  const clearFilters = useCallback(() => {
    dispatch({
      type: filterActions.CLEAR_FILTERS,
    });
  }, []);

  const enableFilters = useCallback(() => {
    dispatch({
      type: filterActions.ENABLE_FILTERS,
    });
  }, []);

  const disableFilters = useCallback(() => {
    dispatch({
      type: filterActions.DISABLE_FILTERS,
    });
  }, []);

  const handleSortByChange = useCallback(newSortBy => {
    dispatch({
      type: actions.SET_SORT_BY,
      payload: {
        sortBy: newSortBy,
      },
    });
  }, []);

  const handleKeywordSearch = useCallback(value => {
    dispatch({
      type: filterActions.SET_KEYWORD_FILTER,
      payload: { keyword: value },
    });
  }, []);

  const applyFilters = useCallback(() => {
    dispatch({
      type: filterActions.APPLY_FILTERS,
      payload: {
        checkBoxFilterNames: [
          'services',
          'spaces',
          'venues',
          'createdByUsers',
          'statuses',
          'paymentStatuses',
        ],
      },
    });
  }, []);

  const changePagination = useCallback((page, perPage) => {
    dispatch({
      type: actions.CHANGE_PAGINATION,
      payload: {
        page,
        perPage,
      },
    });
  }, []);

  const handleStartDateChange = useCallback(startDate => {
    dispatch({
      type: filterActions.SET_START_DATE,
      payload: { startDate },
    });
  }, []);

  const handleEndDateChange = useCallback(endDate => {
    dispatch({
      type: filterActions.SET_END_DATE,
      payload: { endDate },
    });
  }, []);

  const removeBooking = useCallback(
    async (id, reason, reasonMessage) => {
      await apiRequest.delete(`/v1/bookings/${id}`, {
        params: { reason, reasonMessage },
      });

      fetchBookings();
    },
    [fetchBookings, apiRequest]
  );

  const removeBookingItems = useCallback(
    async (bookingId, ids, reason, reasonMessage) => {
      await apiRequest.delete(`/v1/bookings/${bookingId}/booking_items`, {
        params: { ids, reason, reasonMessage },
      });

      fetchBookings();
    },
    [apiRequest, fetchBookings]
  );

  const actionMethods = {
    fetchBookings,
    fetchBookingItems,
    clearReport,
    createReport,
    fetchReport,
    fetchAllBookingsMetadata,
    fetchAllBookingItemsMetadata,
    setSelectedItem,
    uncheckFilters,
    checkAllFilters,
    clearFilters,
    enableFilters,
    disableFilters,
    handleSortByChange,
    handleKeywordSearch,
    applyFilters,
    changePagination,
    handleStartDateChange,
    handleEndDateChange,
    removeBookingItems,
    removeBooking,
  };

  return (
    <BookingsContext.Provider
      value={{
        bookings,
        bookingItems,
        actions: actionMethods,
        loading,
        filtersEnabled,
        sortBy,
        filters,
        totalCount,
        count,
        allBookingsCount,
        initialized,
        pagination,
        report,
        metadataLoaded,
      }}
    >
      {children}
    </BookingsContext.Provider>
  );
};

export default BookingsProvider;

export const useBookingsLoader = () => {
  const { loggedIn } = useContext(AuthContext);
  const context = useContext(BookingsContext);
  const {
    metadataLoaded,
    report,
    actions: { fetchBookings, fetchAllBookingsMetadata, fetchReport },
  } = context;

  useEffect(() => {
    if (loggedIn && metadataLoaded) fetchBookings();
  }, [loggedIn, metadataLoaded, fetchBookings]);

  useEffect(() => {
    if (loggedIn) fetchAllBookingsMetadata();
  }, [loggedIn, fetchAllBookingsMetadata]);

  useInterval(
    () => {
      fetchReport();
    },
    1000,
    report.isReady === false
  );

  return context;
};

export const useBookingItemsLoader = () => {
  const { loggedIn } = useContext(AuthContext);
  const context = useContext(BookingsContext);
  const {
    metadataLoaded,
    report,
    actions: { fetchBookingItems, fetchAllBookingItemsMetadata, fetchReport },
  } = context;

  useEffect(() => {
    if (loggedIn && metadataLoaded) fetchBookingItems();
  }, [loggedIn, metadataLoaded, fetchBookingItems]);

  useEffect(() => {
    if (loggedIn) fetchAllBookingItemsMetadata();
  }, [loggedIn, fetchAllBookingItemsMetadata]);

  useInterval(
    () => {
      fetchReport();
    },
    1000,
    report.isReady === false
  );

  return context;
};

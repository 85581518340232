import { useEffect, useRef, useState } from 'react';

export const useInterval = (
  callback,
  delay,
  apply = false,
  retriesLimit = -1,
  failureCallback = () => {}
) => {
  const savedCallback = useRef();
  const [retriesCount, setRetriesCount] = useState(0);
  const [failed, setFailed] = useState(false);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (apply && !failed) {
      const tick = () => {
        if (retriesLimit > 0) {
          if (retriesCount >= retriesLimit) {
            setFailed(true);
            failureCallback();
            return null;
          }

          savedCallback.current();

          return setRetriesCount(currentRetriesCount => {
            return currentRetriesCount + 1;
          });
        }

        return savedCallback.current();
      };

      if (delay != null) {
        const id = setInterval(tick, delay);

        return () => {
          clearInterval(id);
        };
      }
    }
    return null;
  }, [delay, apply, retriesLimit, retriesCount, failureCallback, failed]);

  return { failed };
};

export default useInterval;

export const reportsActions = {
  CREATE_REPORT: 'CREATE_REPORT',
  CLEAR_REPORT: 'CLEAR_REPORT',
};

export const reportsReducer = (state, action) => {
  switch (action.type) {
    case reportsActions.CREATE_REPORT:
      return {
        ...state,
        report: action.payload.report,
      };
    case reportsActions.CLEAR_REPORT:
      return {
        ...state,
        report: {},
      };
    default:
      return state;
  }
};

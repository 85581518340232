import { dynamicFilter } from 'utils/dynamicFilters';

export const dynamicFilters = (filters, aggs) => {
  return {
    ...filters,
    createdByUsers: dynamicFilter('createdByUsers', filters, aggs),
    venues: dynamicFilter('venues', filters, aggs),
    spaces: dynamicFilter('spaces', filters, aggs),
    services: dynamicFilter('services', filters, aggs),
    statuses: dynamicFilter('statuses', filters, aggs),
    paymentStatuses: dynamicFilter('paymentStatuses', filters, aggs),
  };
};
